<template>
  <b-card>
    <template #header>
      <i class="bx bx-building-house"></i>
      {{ $t("address.label") }}
    </template>
    <form-input 
      icon="bx-building"
      label="address.company"
      rules="required"
      v-model="item.company"
    />
    <form-input 
      icon="bx-info-circle"
      label="address.description"
      v-model="item.description"
    />
    <!-- <form-input 
      icon="bx-question-mark"
      label="address.other"
      v-model="item.other"
    /> -->
    <div class="row row-cols-1 row-cols-xl-2">
      <div class="col">
        <form-input 
          icon="bx-tag bx-rotate-180"
          label="address.street"
          rules="required"
          v-model="item.street"
        />
      </div>
      <div class="col">
        <form-input 
          icon="bxs-tag-x bx-rotate-180"
          label="address.house-number"
          v-model="item.house_number"
        />
      </div>
    </div>
    <div class="row row-cols-1 row-cols-xl-2">
      <div class="col">
        <form-input 
          icon="bx-code"
          label="address.postcode"
          v-model="item.postcode"
        />
      </div>
      <div class="col">
        <form-input 
          icon="bxs-city"
          label="address.locality"
          rules="required"
          v-model="item.locality"
        />
      </div>
    </div>

    <div class="row row-cols-1 row-cols-xl-2">
      <div class="col">
        <form-input 
          icon="bx-fullscreen"
          label="address.state"
          v-model="item.state"
        />
      </div>
      <div class="col">
        <form-input 
          icon="bx-globe"
          type="country"
          label="address.country"
          rules="required"
          :clearable="false"
          v-model="item.country"
        />
      </div>
    </div>



    
  </b-card>
</template>

<script>
import FormInput from "@/components/Form/Input";

export default {
  components: {FormInput},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>