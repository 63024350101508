<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card :header="$t('locations.singular')">
          <template #header>
            <i class="bx bxs-map"></i>
            {{ $t("locations.singular") }}
          </template>

          <form-input 
            type="checkbox"
            label="common.active" 
            icon="bx-check-circle"
            v-model="item.active"
            switch
            size="lg"
          />

          <form-input 
            type="text"
            label="common.name" 
            icon="bx-purchase-tag"
            rules="required"
            v-model="item.name"
          />

          <div class="row row-cols-1 row-cols-xl-2">
            <template v-if="userIsSuperAdmin">
              <div class="col">
                <form-input 
                  label="locations.type" 
                  icon="bx-sort"
                  type="select"
                  v-model="item.type" 
                  rules="required"
                  :reduce="opt => opt.code" 
                  :options="resource.types | transMap" 
                  :clearable="false" 
                />
              </div>
              <div v-if="item.isDropoff" class="col">
                <form-input 
                  label="locations.class" 
                  icon="bx-building"
                  type="select"
                  v-model="item.class" 
                  rules="required"
                  :reduce="opt => opt.code" 
                  :options="resource.classes | transMap" 
                  :clearable="false" 
                />
              </div>
              <div v-if="item.isLocker" class="col">
                <form-input 
                  label="locations.locker_service" 
                  icon="bx-bolt-circle"
                  type="select"
                  :disabled="item.$exists && item.active"
                  v-model="item.locker_service" 
                  rules="required"
                  :reduce="opt => opt.code" 
                  :options="resource.locker_services | transMap" 
                  :clearable="false" 
                />
              </div>
            </template>

            <div class="col">
              <form-input 
                v-if="!item.isMerchant"
                type="country"
                icon="bx-globe"
                rules="required"
                label="common.country"
                v-model="item.country"
              />
            </div>
            <template v-if="item.isDropoff">
              <div class="col">
                <form-input 
                  type="select"
                  icon="bx-time"
                  rules="required"
                  label="locations.timezone"
                  :options="timezones"
                  v-model="item.timezone"
                />
              </div>
              <div class="col">
                <form-input 
                  label="locations.code" 
                  icon="bxs-purchase-tag"
                  rules="required"
                  v-model="item.code"
                />
              </div>
              <div v-if="item.isDropoff" class="col">
                <form-input 
                  label="locations.zone" 
                  icon="bx-map-pin"
                  rules="min:1|max:1"
                  :maxlength="1"
                  v-model="item.zone"
                />
              </div>
            </template>
          </div>
        </b-card>
        <b-card>
          <template #header>
            <i class="bx bx-map-alt"></i>
            {{ $t("locations.coordinates") }}
          </template>

          <b-form-group>
            <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
              <i class="bx bx-grid" /> {{$t('locations.what3words')}}
            </legend>
            <b-input-group>
              <b-form-input v-model="words" />
              <b-input-group-append>
                <b-button variant="primary" @click="loadWords">{{$t('common.resolve')}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input 
                type="coordinate"
                label="locations.latitude" 
                icon="bx-vertical-center"
                rules="required|latitude"
                v-model="item.latitude"
              />
            </div>
            <div class="col">
              <form-input 
                type="coordinate"
                label="locations.longitude" 
                icon="bx-horizontal-center"
                rules="required|longitude"
                v-model="item.longitude"
              />
            </div>
          </div>
        </b-card>
      </div>
      <div class="col mb-4">
        <address-form :item="item.address" />
        <b-card v-if="item.isDropoff">
          <template #header>
            <i class="bx bxs-time"></i>
            {{ $t("locations.hours") }}
          </template>

          <form-input 
            type="textarea"
            label="locations.hours" 
            icon="bx-time"
            v-model="item.hours"
          />
        </b-card>
        <merchant-card v-if="userIsSuperAdmin && item.isPickup" :item="item" rules="required" />
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import AddressForm from "@/components/Form/AddressForm";
import MerchantCard from "@/components/Form/MerchantCard";
import FormInput from "@/components/Form/Input";
import { wordsToCoordinates } from '@/services/What3words'

export default {
  extends: ItemEdit,
  components: { ItemEditTemplate , FormInput, AddressForm, MerchantCard },
  data() {
    return {
      words: null
    }
  },
  computed: {
    timezones() {
      return Intl.supportedValuesOf('timeZone').map(tz => ({label: tz, code: tz}))
    }
  },
  methods: {
    async loadWords() {
      try {
        let {data: {coordinates}} = await wordsToCoordinates(this.words)
        
        this.$set(this.item,'latitude',coordinates.lat)
        this.$set(this.item,'longitude',coordinates.lng)
        this.words = null
      } catch ({response: {data}}) {
        this.$bvModal.msgBoxOk(data.error.message,{
          autoFocusButton: 'ok',
          okVariant: 'danger'
        })
      }
    }
  }
};
</script>